import React, { Component } from "react";
import {Link} from 'react-router-dom';
import api from 'services/api';
import {toast, Card, CardBody, CardTitle, Container, Input} from 'mdbreact';
import CircularProgress from '@material-ui/core/CircularProgress';
import {withI18n} from 'react-i18next'


class AgencyUser extends Component {

   constructor(props) {
      super(props)
      this.state = {
         user_id: props.match.params.id,
         user: null
      }
   }

   componentDidMount() {
      this.onStart()
   }

   onStart = async() => {
      const {user_id} = this.state
      const user_res = await api.get(`wp/v2/users/${user_id}`)
      const user = user_res.data
      console.log(user)
      this.setState({ user })
   }

   render() {
      const {user} = this.state
      const {t} = this.props
      if(user){
         return(
            <Container>
               <Card>
                  <CardBody>
                     <h1>{t('User')}</h1>

                     <Input label="First Name" value={user.acf.first_name} disabled />
                     <Input label="Last Name" value={user.acf.last_name} disabled />
                     <Input label="Phone Number" value={user.acf.mobile_no} disabled />
                     <Input label="Email Address" value={user.name} disabled />

                     {user.acf.profile_picture &&
                        <div>
                           <p>{t('Profile Picture')}</p>
                           <img src={user.acf.profile_picture.url} width="200px" />
                        </div>
                     }

                  </CardBody>
               </Card>

            </Container>
         )
      }else{
         return(
            <Container>
               <Card>
                  <CardBody>
                     <div className="text-center spacious">
                        <CircularProgress  />
                     </div>
                  </CardBody>
               </Card>
            </Container>
         )
      }
   }
}

export default withI18n()(AgencyUser)
