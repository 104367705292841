import React, {Component} from 'react'
import {Redirect, Link} from 'react-router-dom'
import { Container, Row, Col, Input, Button, toast, ToastContainer, CardBody, Card } from 'mdbreact';
import api_public from 'services/api_public';
import {withI18n} from 'react-i18next'

class Register extends Component {
   constructor(){
      super()
      this.state = {
         is_login: localStorage.getItem('token') ? true : false ,
         user: {},
      }
   }

   onRegister = async (e) => {
      e.preventDefault()
      const {user} = this.state
      if(!user.email || !user.password || !user.first_name || !user.last_name ){
         toast.error("Fields cannot be empty")
         return
      }
      if(user.password !== user.password_confirm){
         toast.error("Passwords don't match")
         return
      }
      try{
         const response = await api_public.post(`adhoc/v1/users/register`, user)
         this.setState({ user: {} })
         toast.success("Registration Successful. Please check you email to verify the account")
      }
      catch(e){
         console.log(e.response.data.message);
         toast.error('Registeration Failed')
      }
   }

   updateText = (e, f) => {
      let {user} = this.state
      user[f] = e.target.value
      this.setState({user})
   }

   render() {
      const {user, is_login} = this.state
      const {from} = this.props.location.state || { from: { pathname: '/' }}
      const {t} = this.props
      if( is_login ) {
         return (
            <Redirect to={from} />
         )
      }else{
         return(
            <div id="register" className="container spacious" >
               <Container>
                  <Row>
                     <Col md="3"></Col>
                     <Col md="6">
                        <Card>
                           <CardBody>
                              <form onSubmit={this.onRegister}>
                                 <p className="h3 text-center mb-4">{t('Register')}</p>
                                 <div className="grey-text">
                                    <Input label={t('First Name')} icon="user" group type="text" validate error="wrong" success="right"
                                       onChange={(e)=>{ this.updateText(e, 'first_name') } } value={user.first_name}
                                    />
                                    <Input label={t('Last Name')} icon="phone" group type="text" validate error="wrong" success="right"
                                       onChange={(e)=>{ this.updateText(e, 'last_name') } } value={user.last_name}
                                    />
                                    <Input label={t('Mobile Number')} icon="envelope" group type="text" validate error="wrong" success="right"
                                       onChange={(e)=>{ this.updateText(e, 'mobile_no') } } value={user.mobile_no}
                                    />
                                    <Input label={t('Email')} icon="envelope" group type="email" validate error="wrong" success="right"
                                       onChange={(e)=>{ this.updateText(e, 'email') } } value={user.email}
                                    />
                                    <Input label={t('Password')} icon="lock" group type="password" validate
                                       onChange={(e)=>{ this.updateText(e, 'password') } } value={user.password}
                                    />
                                    <Input label={t('Confirm your password')} icon="lock" group type="password" validate
                                       onChange={(e)=>{ this.updateText(e, 'password_confirm') } } value={user.password_confirm}
                                    />
                                 </div>
                                 <div className="text-center">
                                    <Button type="submit">{t('Register')}</Button>
                                 </div>
                                 <div className="text-center spacious">
                                    <Link to="/agency/register">{t('Agency Register')}</Link>
                                 </div>
                              </form>
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>
               </Container>


               <ToastContainer
                  hideProgressBar={true}
                  newestOnTop={true}
                  autoClose={false}
               />

            </div>
         )
      }
   }
}

export default withI18n()(Register)
