import React, { Component } from "react";
import {Link} from 'react-router-dom';
import api from 'services/api';
import { toast, Container, Button, Modal, ModalBody, ModalHeader, ModalFooter, Row, Input, Card, CardBody, CardImage, CardTitle, CardText } from 'mdbreact';
import MButton from '@material-ui/core/Button';
import Loader from 'components/Loader'
import {withI18n} from 'react-i18next'

class Profile extends Component {

   constructor() {
      super()
      this.state = {
         user: null,
         profile_picture_attached: false,
         profile_picture: null,
         passwords: {"btn_disabled": false}
      }
   }

   componentDidMount() {
      this.onStart()
   }

   onStart = async() => {
      const response = await api.get(`wp/v2/users/me`)
      const {data} = response
      const res = await api.get(`wp/v2/users/${data.id}`)
      const user = res.data
      console.log(user);
      this.setState({user})
   }

   updateText = (e, f) => {
      let {user} = this.state
      user.acf[f] = e.target.value
      this.setState({user})
   }
   updatePassword = (e, f) => {
      let {passwords} = this.state
      passwords[f] = e.target.value
      this.setState({passwords})
   }
   updateLogo = async(e) => {
      const {user} = this.state
      let file = e.target.files[0]

      this.setState({ profile_picture: file, profile_picture_attached: true })
   }
   onUpdate = async(e) => {
      e.preventDefault()
      const {user, profile_picture} = this.state

      if(profile_picture){
         let formData = new FormData()
         formData.append('file', profile_picture)
         const upload = await api.post(`adhoc/v1/media`, formData)
         user.acf.profile_picture = upload.data.id
      }

      let data = {}
      data.fields = user.acf
      const response = await api.post(`wp/v2/users/${user.id}`, data)
      toast.success("Updated")
      this.onStart()
      this.setState({profile_picture_attached: false, profile_picture: null})

   }

   onUpdatePassword = async() => {
      const {passwords, agency} = this.state

      if(!passwords.current_password || !passwords.new_password || !passwords.password_confirm){
         toast.warn("Fields are empty")
         return
      }
      if(passwords.new_password !== passwords.password_confirm){
         toast.warn("Passwords don't match")
         return
      }
      this.setState({ passwords: {...passwords, btn_disabled: true} })

      try{
         const response = await api.post(`adhoc/v1/user/change_password`, passwords)
         const {data} = response
         if(data.code == 200){
            toast.success("Password Changed")
         }
      } catch(e) {
         toast.error(e.response.data.message)
      }
      this.setState({ passwords: {current_password: '', new_password: '', password_confirm: '' , btn_disabled: false} })

   }

   render() {
      const {user, passwords} = this.state
      const {t} = this.props
      if(user){
         return(
            <Container>
               <Card>
                  <CardBody>
                     <h1>{t('Profile')}</h1>
                     <form onSubmit={this.onUpdate}>
                        <Input label={t('Email')} icon="envelope" type="text" value={user.name} disabled/>

                        {[['First Name','user','text','first_name'],['Last Name','user','text','last_name'],
                        ['Mobile Number','phone','text','mobile_no']].map( (a, i) => (
                           <Input label={t(a[0])} icon={a[1]}  type={a[2]} validate key={i}
                              onChange={(e)=>{ this.updateText(e, a[3]) } } value={user.acf[a[3]]}
                           />
                        ))}

                        {user.acf.profile_picture &&
                           <div className="spacious"><img src={user.acf.profile_picture.url} width="200px" ></img></div>
                        }

                        <input accept="image/*" className='hide' id="contained-button-file" type="file" onChange={this.updateLogo} />
                        <label htmlFor="contained-button-file">
                           <MButton variant="contained" component="span" color="primary" >
                              {user.acf.profile_picture ?
                                 <span>{t('Update Profile Picture')}</span>
                                 :
                                 <span>{t('Upload Profile Picture')}</span>
                              }
                           </MButton>
                        </label>
                        {this.state.profile_picture_attached &&
                           <div>{t('Image Attached')}</div>
                        }

                        <Button block className="spacious" type="submit" >
                           {t('Update')}
                        </Button>

                     </form>
                  </CardBody>
               </Card>

               <Card>
                  <CardBody>
                     <CardTitle>{t('Password')}</CardTitle>
                     {[['Current Password','current_password'],['New Password','new_password'],['Confirm Password','password_confirm']].map((a, i) => (
                        <Input label={t(a[0])} icon='lock'  type='password' validate key={i}
                           onChange={(e)=>{ this.updatePassword(e, a[1]) } } value={passwords[a[1]]}
                        />
                     ))}

                     <Button block className="spacious" onClick={this.onUpdatePassword} disabled={passwords.btn_disabled ? true : false }>
                        {passwords.btn_disabled ?
                           <span>{t('Updating')}</span>
                           :
                           <span>{t('Update Password')}</span>
                        }
                     </Button>
                  </CardBody>
               </Card>

            </Container>
         )
      }else{
         return <Container><Loader /></Container>
      }
   }
}

export default withI18n()(Profile)
