import React, { Component } from "react";
import {Link, Redirect} from 'react-router-dom';
import api from 'services/api';
import { toast, Container, Button, Modal, ModalBody, ModalHeader, ModalFooter, Row, Input, Card, CardBody } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import Loader from 'components/Loader'
import {withI18n} from 'react-i18next'


class Jobs extends Component {

   constructor() {
      super()
      this.state = {
         jobs: null,
         modalOpen: false,
         new_job: {
            title: '',
         },
         new_job_id: null
      }
   }

   componentDidMount() {
      this.onStart()
   }

   onStart = async() => {
      const id = localStorage.getItem('id')
      const response = await api.get(`wp/v2/jobs?status=draft+publish&author=${id}`)
      const jobs = response.data
      console.log(jobs);
      this.setState({ jobs })
   }
   onToggleModal = () => {
      this.setState({ modalOpen: !this.state.modalOpen })
   }

   changeNewJobText = (e, f) => {
      let {new_job} = this.state
      new_job[f] = e.target.value
      this.setState( {new_job} )
   }
   onCreate = async() => {
      const {new_job} = this.state
      this.onToggleModal()
      const response = await api.post(`wp/v2/jobs`, {
         ...new_job, status: 'draft'
      })
      const result = response.data
      if(result){
         toast.success("New Job Successfully Created")
         this.setState({new_job: {title: ''}, new_job_id: result.id})
      }else{
         toast.error('Error Creating Job')
      }
   }


   render() {
      const {jobs, modalOpen, new_job, new_job_id} = this.state
      const { SearchBar } = Search
      const {t} = this.props

      if(jobs){
         if(new_job_id) {
            return <Redirect to={`/agency/edit-job/${new_job_id}`} />
         }
         return(
            <Container id="jobs">

               <Card>
                  <CardBody>
                     <Button onClick={this.onToggleModal}>{t('Create New Job')}</Button>
                     <Link to="/agency/preview-job" target="_blank"><Button color="success" >{t('Ads Preview')}</Button></Link>
                     <ToolkitProvider search
                        keyField="id" data={ jobs } columns={ this.columns } >
                        {
                           props => (
                              <div>
                                 <SearchBar { ...props.searchProps } className="search-bar" />
                                 <BootstrapTable
                                    { ...props.baseProps }
                                    pagination={ paginationFactory({sizePerPage: 50}) }
                                 />
                              </div>
                           )
                        }
                     </ToolkitProvider>
                  </CardBody>
               </Card>



               <Modal isOpen={modalOpen} toggle={this.onToggleModal}>
                  <ModalHeader toggle={this.onToggleModal}>{t('Create New Job')}</ModalHeader>
                  <ModalBody>
                     <form>
                        <Input label={t('New Job Title')} value={new_job.title}
                           onChange={(e)=>this.changeNewJobText(e, 'title')}
                        />
                     </form>
                  </ModalBody>
                  <ModalFooter>
                     <Button color="blue-grey" onClick={this.onToggleModal}>{t('Close')}</Button>{' '}
                     <Button color="success" onClick={this.onCreate}>{t('CREATE')}</Button>
                  </ModalFooter>
               </Modal>


            </Container>
         )
      }else{
         return <Container><Loader /></Container>
      }
   }



   columns = [{
      dataField: 'title',
      text: 'Job Title',
      sort: true,
      formatter: (cell, row) => {
         return <Link to={`/agency/edit-job/${row.id}`} >{cell.rendered}</Link>
      },
      filterValue: (cell, row) => cell.rendered
   },{
      dataField: 'slug',
      text: 'Link',
      formatter: (cell, row) => {
         return <Link to={`/agency/preview-job/${cell}`} target="_blank" >Preview</Link>
      }
   },{
      dataField: 'acf.start_date',
      text: 'Start Date',
      sort: true,
      formatter: (cell, row) => { return row.acf.start_date },
      filterValue: (cell, row) => { return row.acf.start_date },
   },{
      dataField: 'acf.end_date',
      text: 'End Date',
      sort: true,
      formatter: (cell, row) => { return row.acf.end_date },
      filterValue: (cell, row) => { return row.acf.end_date },
   },{
      dataField: 'id',
      text: 'Applications',
      formatter: (cell, row) => { return <Link to={`/agency/edit-job/${row.id}#applications`} >Applications</Link> }
   },{
      dataField: 'status',
      'text': 'Status',
      sort: true,
   },{
      dataField: 'duplicate',
      text: 'Duplicate',
      formatter: (cell, row) => {
         return <Button size="sm" onClick={()=>this.onDuplicate(row.id)}>Duplicate</Button>
      }
   }];

   onDuplicate = async(id) => {
      await api.post(`adhoc/v1/duplicate`, {post_id: id})
      this.onStart()
   }

}

export default withI18n()(Jobs)
