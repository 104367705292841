import React, { Component } from "react";
import {Link} from 'react-router-dom';
import api from 'services/api';
import {toast, Container} from 'mdbreact';

class Home extends Component {

   constructor() {
      super()
      this.state = {

      }
   }

   componentDidMount() {

   }

   render() {
      return(
         <Container>
            <h1>Dashboard</h1>

         </Container>
      )
   }
}

export default Home
