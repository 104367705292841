import React from "react"
import {Link} from 'react-router-dom'
import { ToastContainer, Navbar, NavbarNav, NavItem, NavLink, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Fa, SideNavItem, SideNavCat, SideNavNav, SideNav, Container, NavbarToggler, Collapse, Button } from 'mdbreact'
import { withI18n } from 'react-i18next';


class Wrapper extends React.Component {

   constructor(){
      super()
      this.state = {
         timeout: 30, // minute
         toggleStateA: false,
         breakWidth: 1300,
         windowWidth: 0,
         isMenuOpen: false,
         collapse: false
      }
   }

   componentDidMount() {
      this.sessionTimeout()
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
   }
   componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
   }

   logout = () => {
      localStorage.clear();
      window.location.reload();
   }


   handleResize = () => this.setState({
      windowWidth: window.innerWidth
   });

   onLogout = () => {
      let c = window.confirm(this.props.t('confirm logout'))
      if(!c) return
      localStorage.clear();
   }
   onToggleMobileNav = () => {
      this.setState({ collapse: !this.state.collapse})
   }

   render() {
      // const location = window.location.pathname.split('/')[1]
      const role = localStorage.getItem('role')
      const {windowWidth, collapse} = this.state
      const { t, i18n } = this.props;

      return(
         <div id="wrapper" >


            <div  >
               <Navbar color="indigo" double expand="md" fixed="top"  >
                  { windowWidth < 768 &&
                     <button onClick = { this.onToggleMobileNav } >
                        <Fa icon="bars" size="2x" style={{color: 'white'}} />
                     </button>
                  }
                  <Collapse isOpen = { collapse } navbar>
                     <NavbarNav left>
                        <NavItem >
                           <NavLink to="/" >Adhoc</NavLink>
                        </NavItem>
                        {
                           role === 'agency' ?
                           <NavbarNav>
                              {/* <NavItem>
                                 <NavLink to="/agency/dashboard">Dashboard</NavLink>
                              </NavItem> */}
                              <NavItem>
                                 <NavLink to="/agency/jobs">{t('jobs')}</NavLink>
                              </NavItem>
                           </NavbarNav>
                           :
                           <NavItem></NavItem>
                        }

                        <NavItem >
                           <NavLink to="/agency" >{t('Agencies')}</NavLink>
                        </NavItem>

                     </NavbarNav>
                     {role === 'user' ?
                     <NavbarNav right >
                        <NavItem>
                           <Dropdown>
                              <DropdownToggle nav caret>{t('Language')}</DropdownToggle>
                              <DropdownMenu>
                                   <DropdownItem href="#" onClick={()=>i18n.changeLanguage('en')}>English</DropdownItem>
                                   <DropdownItem href="#" onClick={()=>i18n.changeLanguage('zh')}>中文</DropdownItem>
                              </DropdownMenu>
                           </Dropdown>
                        </NavItem>
                        <NavItem>
                           <NavLink to="/profile" >{t('Profile')}</NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink to="#" onClick={this.onLogout} >{t('Logout')}</NavLink>
                        </NavItem>
                     </NavbarNav>

                     : role === 'agency' ?
                     <NavbarNav right >
                        <NavItem>
                           <Dropdown>
                              <DropdownToggle nav caret>{t('Language')}</DropdownToggle>
                              <DropdownMenu>
                                   <DropdownItem href="#" onClick={()=>i18n.changeLanguage('en')}>English</DropdownItem>
                                   <DropdownItem href="#" onClick={()=>i18n.changeLanguage('zh')}>中文</DropdownItem>
                              </DropdownMenu>
                           </Dropdown>
                        </NavItem>
                        <NavItem>
                           <NavLink to="/agency/profile" >{t('Profile')}</NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink to="#" onClick={this.onLogout} >{t('Logout')}</NavLink>
                        </NavItem>
                     </NavbarNav>

                     :
                     <NavbarNav right >
                        <NavItem>
                           <Dropdown>
                              <DropdownToggle nav caret>{t('Language')}</DropdownToggle>
                              <DropdownMenu>
                                   <DropdownItem href="#" onClick={()=>i18n.changeLanguage('en')}>English</DropdownItem>
                                   <DropdownItem href="#" onClick={()=>i18n.changeLanguage('zh')}>中文</DropdownItem>
                              </DropdownMenu>
                           </Dropdown>
                        </NavItem>
                        <NavItem>
                           <NavLink to="/login" >{t('Login')}</NavLink>
                        </NavItem>
                        <NavItem>
                           <NavLink to="/register" >{t('Register')}</NavLink>
                        </NavItem>
                     </NavbarNav>

                  }


               </Collapse>
            </Navbar>
            <main >
               <div id="content">
                  {this.props.component}
               </div>
            </main>

            <ToastContainer
               hideProgressBar={true}
               newestOnTop={true}
               autoClose={5000}
            />

         </div>




      </div>
   )
}

sessionTimeout = () => {
   const {timeout} = this.state
   let prev_time = localStorage.getItem('timeout')
   let new_time = new Date().getTime()
   if(new_time > (parseInt(prev_time) + (timeout*60*1000) ) ){
      this.logout()
   }else{
      localStorage.setItem('timeout', new_time)
   }

}

}

export default withI18n()(Wrapper)
