import React, { Component } from "react";
import {Link} from 'react-router-dom';
import api_public from 'services/api_public';
import { Container, Button, Card, CardBody, CardImage, CardTitle, CardText, Input } from 'mdbreact';
import MCheckbox from '@material-ui/core/Checkbox';
// import MCheckBoxIcon from '@material-ui/icons/CheckBox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl'
import { withI18n } from "react-i18next"


class Filter extends Component {

   constructor(props) {
      super(props)
      this.state = {
         cats: this.props.cats,
         cats_checked: [],
         cats_selected: [],
      }
   }

   componentDidMount() {
      this.onStart()
   }

   onStart = async() => {
      let {cats_checked, cats} = this.state
      cats.forEach( (c, i) => {
         cats_checked[i] = false
      })
      this.setState({ cats_checked })
   }

   onChangeCat = id => e => {
      let {cats_checked, cats} = this.state
      cats_checked[id] = e.target.checked
      this.setState({ cats_checked })
      let cats_selected = []
      cats.forEach( (cat, i) => {
         if(cats_checked[i]){
            cats_selected.push(cat.id)
         }
      })
      this.props.onFilterCat( cats_selected )
   }



   render() {
      const {cats, cats_checked} = this.state
      const {t} = this.props

      return(
         <div id="filter" >
            <Card>
               <CardBody>
                  <h3>{t('Job Filter')}</h3>
                  <hr/>
                  <FormControl >
                     <h5>{t('Category')}</h5>
                     <FormGroup >
                        {cats && cats.map( (cat, i) => (
                           <FormControlLabel key={i}
                              control={
                                 <MCheckbox
                                    checked={cats_checked[i]}
                                    onChange={this.onChangeCat(i)}
                                 />
                              }
                              label={cat.name}
                           />
                        ))}
                     </FormGroup>
                  </FormControl>

                  <hr/>



               </CardBody>
            </Card>
         </div>
      )
   }






}
export default withI18n()(Filter)


//const query = new URLSearchParams(window.location.search)
// const value = query.get('key')
