import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import { Container, Row, Col, Input, Button, toast, ToastContainer, CardBody, Card } from 'mdbreact'
import api_public from 'services/api_public';
import MButton from '@material-ui/core/Button'
import {withI18n} from 'react-i18next'

class Register extends Component {
   constructor(){
      super()
      this.state = {
         is_login: localStorage.getItem('token') ? true : false ,
         agency: {},
         logo_attached: false
      }
   }

   onRegister = async (e) => {
      e.preventDefault()
      const {agency} = this.state
      if(!agency.company_name || !agency.email || !agency.password || !agency.contact_person_no || !agency.company_info){
         toast.error("Fields cannot be empty")
         return
      }
      if(agency.password !== agency.password_confirm){
         toast.error("Passwords don't match")
         return
      }
      try{
         let formData = new FormData()
         for(let key in agency){
            if(agency.hasOwnProperty(key)){
               formData.append(key, agency[key])
            }
         }
         const response = await api_public.post(`adhoc/v1/agency/register`, formData)
         toast.success("Registration Successful. Please check you email to verify the account")
      }
      catch(e){
         console.log(e.response.data.message);
         toast.error('Registeration Failed - ' + e.response.data.message)
      }
   }

   updateText = (e, f) => {
      let {agency} = this.state
      agency[f] = e.target.value
      this.setState({agency})
   }
   uploadLogo = (e) => {
      let file = e.target.files[0]
      this.setState({ agency: {...this.state.agency, logo: file}, logo_attached: true })
   }

   render() {
      const {agency, is_login} = this.state

      const {from} = this.props.location.state || { from: { pathname: '/dashboard' }}
      const {t} = this.props
      if( is_login ) {
         return (
            <Redirect to={from} />
         )
      }else{
         return(
            <div id="register" className="container spacious" >
               <Container>
                  <Row>
                     <Col md="1"></Col>
                     <Col md="9">
                        <Card>
                           <CardBody>
                              <form onSubmit={this.onRegister}>
                                 <p className="h3 text-center mb-4">{t('Register')}</p>
                                 <div className="grey-text">
                                    <Input label={t('Company Name')} icon="building"  type="text" validate error="wrong" success="right"
                                       onChange={(e)=>{ this.updateText(e, 'company_name') } } value={agency.company_name}
                                    />
                                    <Input label={t('Business Registration Number')} icon="file"  type="text" validate error="wrong" success="right"
                                       onChange={(e)=>{ this.updateText(e, 'business_registration_no') } } value={agency.business_registration_no}
                                    />
                                    <Input label={t('Office Contact Number')} icon="phone" type="text" validate
                                       onChange={(e)=>{ this.updateText(e, 'office_no') } } value={agency.office_no}
                                    />
                                    <Input label={t('Contact Person Number')} icon="phone" type="text" validate
                                       onChange={(e)=>{ this.updateText(e, 'contact_person_no') } } value={agency.contact_person_no}
                                    />
                                    <Input label={t('Company Info')} icon="info-circle" type="textarea" validate rows="5"
                                       onChange={(e)=>{ this.updateText(e, 'company_info') } } value={agency.company_info}
                                    />
                                    <Input label={t('Company Website (optional)')} icon="chrome" type="text" validate
                                       onChange={(e)=>{ this.updateText(e, 'website') } } value={agency.website}
                                    />

                                    <Input label={t('Email')} icon="envelope"  type="email" validate error="wrong" success="right"
                                       onChange={(e)=>{ this.updateText(e, 'email') } } value={agency.email}
                                    />
                                    <Input label={t('Password')} icon="lock"  type="password" validate
                                       onChange={(e)=>{ this.updateText(e, 'password') } } value={agency.password}
                                    />
                                    <Input label={t('Confirm your password')} icon="lock"  type="password" validate
                                       onChange={(e)=>{ this.updateText(e, 'password_confirm') } } value={agency.password_confirm}
                                    />

                                    <input accept="image/*" className='hide' type="file" id="contained-button-file" onChange={this.uploadLogo} />
                                    <label htmlFor="contained-button-file">
                                       <MButton variant="contained" component="span" color="primary" >
                                          {t('Upload Logo')}
                                       </MButton>
                                    </label>
                                    {this.state.logo_attached &&
                                       <div>{t('Logo Attached')}</div>
                                    }

                                 </div>
                                 <div className="text-center">
                                    <Button type="submit">{t('Register')}</Button>
                                 </div>
                              </form>
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>
               </Container>


               <ToastContainer
                  hideProgressBar={true}
                  newestOnTop={true}
                  autoClose={false}
               />

            </div>
         )
      }
   }
}

export default withI18n()(Register)
