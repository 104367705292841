import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import { Container, Row, Col, Input, Button, toast, ToastContainer, Card, CardBody  } from 'mdbreact';
import {Link} from 'react-router-dom';
import api_public from 'services/api_public'
import {withI18n} from 'react-i18next'

class Login extends Component {
   constructor(){
      super()
      this.state = {
         is_login: localStorage.getItem('token') ? true : false ,
         email: '',
         password: ''
      }
   }

   componentDidMount() {
      let verified = window.location.hash.substr(1)
      if(verified){
         toast.success("You've successfully verified your account. Please login")
      }
   }

   onLogin = async (e) => {
      e.preventDefault()
      const {email, password} = this.state
      try{
         const response = await api_public.post(`jwt-auth/v1/token`, {username: email, password})
         const {data} = response
         let token = ''
         if(token = data.token){
            localStorage.setItem('token', token)
            localStorage.setItem('role', data.user_role[0])
            localStorage.setItem('id', data.user_id)
            localStorage.setItem('timeout', new Date().getTime() )
            window.location.reload();
         }else{
            toast.error('Login Failed')
         }
      }
      catch(e){
         console.log(e.response.data.message)
         if(e.response.data.code == '[jwt_auth] email_not_verified'){
            toast.error('Please verify your account by clicking the link in your email')
         }else{
            toast.error('Incorrect Email address or password')
         }
      }
   }

   render() {
      const {email, password, is_login} = this.state
      const {from} = this.props.location.state || { from: { pathname: '/' }}
      const {t} = this.props
      if( is_login ) {
         return (
            <Redirect to={from} />
         )
      }else{
         return(
            <div id="login" className="container spacious" >
               <Container>
                  <Row>
                     <Col md="3"></Col>
                     <Col md="6">
                        <Card>
                           <CardBody>
                              <form  onSubmit={this.onLogin}>
                                 <p className="h3 text-center mb-4">{t('User Log In')}</p>
                                 <div className="grey-text">
                                    <Input label={t('Type your email')} icon="envelope" group type="email" validate error="wrong" success="right"
                                       onChange={(e)=>{ this.setState({email: e.target.value}) } } value={email}
                                    />
                                    <Input label={t('Type your password')} icon="lock" group type="password" validate
                                       onChange={(e)=>{ this.setState({password: e.target.value}) } } value={password}
                                    />
                                 </div>
                                 <div className="text-center">
                                    <Button type="submit">{t('Login')}</Button>
                                 </div>

                                 <div className="text-center spacious">
                                    <Link to="/agency/login">{t('Agency Login')}</Link>
                                 </div>
                              </form>
                           </CardBody>
                        </Card>
                     </Col>
                  </Row>
               </Container>
               {/* <p>Redirecting to {from.pathname}</p> */}

               <ToastContainer
                  hideProgressBar={true}
                  newestOnTop={true}
                  autoClose={5000}
               />

            </div>
         )
      }
   }
}

export default withI18n()(Login)
