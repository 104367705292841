import axios from 'axios';


export default axios.create({
   // baseURL: `http://adhoc.loc/wp/wp-json`,
   baseURL: `http://adhoc.wunderfauks.com/wp/wp-json`,
   headers: {
      'Authorization': `Bearer ${localStorage.getItem('token')}`,
   }

});
