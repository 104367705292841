import React, { Component } from "react";
import {Link, Redirect} from 'react-router-dom';
import api from 'services/api';
import { toast, Input, Container, Row, Button, Card, CardBody, CardTitle, CardText } from 'mdbreact';
import ApplicationsTab from 'components/ApplicationsTab'
import EditJobTab from 'components/EditJobTab'


import MTabs from '@material-ui/core/Tabs';
import MTab from '@material-ui/core/Tab';





class EditJob extends Component {

   constructor(props) {
      super(props)
      this.state = {
         job_id: props.match.params.id,
         selected_tab: 0,
      }
   }

   componentDidMount() {
      let tab = window.location.hash.substr(1)
      if(tab === 'applications'){
         this.setState({ selected_tab: 1 })
      }
   }



   changeTab = (e, v) => {
      let text = 'edit'
      if(v == 1){
         text = 'applications'
      }
      window.location.hash = text
      this.setState({
         selected_tab: v
      })
   }


   render() {
      const {selected_tab, job_id} = this.state


      return(
         <Container id="job">

            <MTabs value={selected_tab} onChange={this.changeTab} >
               <MTab label="Edit" />
               <MTab label="Applications" />
            </MTabs>

            {selected_tab === 0 ?
               <EditJobTab id={job_id} />
               :
               <ApplicationsTab id={job_id} />
            }

         </Container>

      )


   }
}

export default EditJob
