import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n.js';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


ReactDOM.render((
   <I18nextProvider i18n={i18n}>
      <App />
   </I18nextProvider>
), document.getElementById('root'))
