import React, { Component } from "react";
import {Link} from 'react-router-dom';
import api_public from 'services/api_public';
import api from 'services/api'
import { Container, Button, Card, CardBody, CardImage, CardTitle, CardText, Pagination, PageItem, PageLink, Input, Modal, ModalBody, ModalHeader } from 'mdbreact';
import Filter from 'components/Filter';
import Loader from 'components/Loader'
import {TweenMax} from 'gsap'
import i18n from "i18next"
import { withI18n, reactI18nextModule } from "react-i18next"


class Agency extends Component {

   constructor() {
      super()
      this.state = {
         agencies: null
      }
   }

   componentDidMount() {
      this.onStart()
   }

   onStart = async() => {
      const res = api.get(`wp/v2/users?roles=agency`)
      console.log(res)
   }

   render() {
      const {agencies} = this.state
      const {t} = this.props
      if(agencies) {
         return (
            <div>hi</div>
         )
      }else{
         return <Container><Loader /></Container>
      }
   }

}


export default withI18n()(Agency)
