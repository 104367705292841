import React, { Component } from "react";
import {Link} from 'react-router-dom';
import api_public from 'services/api_public';
import api from 'services/api'
import { Container, Button, Card, CardBody, CardImage, CardTitle, CardText, Pagination, PageItem, PageLink, Input, Modal, ModalBody, ModalHeader } from 'mdbreact';
import Filter from 'components/Filter';
import Loader from 'components/Loader'
import {TweenMax} from 'gsap'
import i18n from "i18next"
import { withI18n, reactI18nextModule } from "react-i18next"



class Home extends Component {

   constructor() {
      super()
      this.state = {
         jobs: null,
         cats: null,
         filter_category: '',
         per_page: 4,
         page: 1,
         total_pages: [],
         search: '',
         preview: window.location.pathname === '/agency/preview-job' ? true : false,
      }
   }



   componentDidMount() {
      this.onGetJobs()
      this.onGetCats()
      window.addEventListener('resize', this.handleResize);
   }
   componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
   }

   handleResize = () => {
      let width = window.innerWidth
      if(width > 992){
         TweenMax.set(".filter", {left: 0})
         TweenMax.set("body", {overflow: 'auto'})
      }else{
         this.hideMobileFilter()
      }
   }

   onGetJobs = async() => {
      const {per_page, filter_category, page, search, preview} = this.state
      let jobs_res
      if(preview){
         const id = localStorage.getItem('id')
         jobs_res = await api.get(`wp/v2/jobs?status=draft+publish&per_page=${per_page}&page=${page}${filter_category}${search}&author=${id}`)
      }else{
         jobs_res = await api_public.get(`wp/v2/jobs?per_page=${per_page}&page=${page}${filter_category}${search}`)
      }
      const jobs = jobs_res.data
      const {headers} = jobs_res
      let total_pages = new Array(parseInt(headers['x-wp-totalpages'])).fill("")
      console.log(jobs);
      this.setState({ jobs, total_pages })
   }
   onGetCats = async() => {
      const cats_res = await api_public.get(`wp/v2/categories?hide_empty=1`)
      const cats = cats_res.data
      this.setState({ cats })
   }

   onFilterCat = (cats_selected) => {
      let filter_category = `&categories=${cats_selected.join()}`
      if(cats_selected.length === 0){
         filter_category = ''
      }
      this.setState({ filter_category }, () => {
         this.onGetJobs()
      })

   }

   changePage = (new_page) => {
      window.scrollTo(0,0);
      this.setState({ page: new_page }, () => {
         this.onGetJobs()
      })
   }
   search_delay;
   onSearch = (e) => {
      let value = e.target.value
      let _this = this
      if(value.length < 3 && value.length !== 0){
         return
      }
      clearTimeout(this.search_delay);
      this.search_delay = setTimeout(function() {
         if(value == ''){
            _this.setState({ search: `` }, () => {
               _this.onGetJobs()
            })
         }else{
            _this.setState({ search: `&search=${value}`, page: 1 }, () => {
               _this.onGetJobs()
            })
         }
      }, 200);

   }

   showMobileFilter = () => {
      TweenMax.to(".filter", 0.3, {left: 0})
      TweenMax.set("body", {overflow: 'hidden'})
   }
   hideMobileFilter = () => {
      TweenMax.to(".filter", 0.3, {left: '-100%'})
      TweenMax.set("body", {overflow: 'auto'})
   }


   render() {
      const {jobs, cats, total_pages, page, preview} = this.state
      const {t} = this.props

      if(jobs && cats){
         return(
            <div className="container">
               <input onChange={this.onSearch} placeholder="Search" className="form-control" id="search" />

               <div className="row">
                  <div className="col-lg-2 filter"  >
                     <div className="filter-cover" onClick={this.hideMobileFilter} ></div>
                     <Filter cats={cats} onFilterCat={this.onFilterCat} />
                  </div>
                  <div className="d-block d-lg-none">
                     <button onClick={this.showMobileFilter} >{t('Filter')}</button>
                  </div>


                  <div className="col-lg-10">

                     <h1>{t('jobs')} {preview && <span>{t('Preview')}</span>}</h1>
                     <div className="cards">
                        {jobs.map ( (job, i) => (
                           <Card key={i}>
                              <CardBody>
                                 <CardTitle>
                                    <Link to={`/job/${job.slug}`}>{job.title.rendered}</Link>
                                 </CardTitle>
                                 <CardText dangerouslySetInnerHTML={{ __html: job.excerpt.rendered}}></CardText>
                                 {job.acf.location &&
                                    <CardText>{t('Location:')} {job.acf.location}</CardText>
                                 }
                                 {job.acf.start_date &&
                                    <CardText>{t('Date:')} {job.acf.start_date} to {job.acf.end_date}</CardText>
                                 }
                                 {preview ?
                                    <Link to={`/agency/preview-job/${job.slug}`} className="btn btn-primary" >{t('View')}</Link>
                                    :
                                    <Link to={`/job/${job.slug}`} className="btn btn-primary" >{t('View')}</Link>
                                 }

                              </CardBody>
                           </Card>
                        ))}
                     </div>

                  
                     <div className="pagination">
                        <Pagination className="pg-blue pagination-lg">
                           <PageItem >
                              <PageLink className="page-link" aria-label="Previous" onClick={()=>this.changePage(page - 1)} >
                                 <span aria-hidden="true">&laquo;</span>
                                 <span className="sr-only">{t('Previous')}</span>
                              </PageLink>
                           </PageItem>
                           {total_pages.map ( (tp, i) => (
                              <PageItem key={i} active={page === i+1 ? true : false} >
                                 <PageLink className="page-link" onClick={()=>this.changePage(i+1)} >
                                    <span aria-hidden="true"  >{i + 1}</span>
                                 </PageLink>
                              </PageItem>
                           )) }
                           <PageItem  >
                              <PageLink className="page-link" aria-label="Next" onClick={()=>this.changePage(page + 1)} >
                                 <span aria-hidden="true">&raquo;</span>
                                 <span className="sr-only">{t('Next')}</span>
                              </PageLink>
                           </PageItem>
                        </Pagination>
                     </div>

                  </div>
               </div>
            </div>
         )
      }else{
         return <Container><Loader /></Container>
      }
   }
}

export default withI18n()(Home)
