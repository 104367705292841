import React, { Component } from "react";
import {Link} from 'react-router-dom';
import api from 'services/api';
import api_public from 'services/api_public';
import { Container, Button, Card, CardBody, CardImage, CardTitle, CardText, toast } from 'mdbreact';
import Map from 'components/Map'
import Loader from 'components/Loader'
import {withI18n} from 'react-i18next'


class Job extends Component {

   constructor(props) {
      super(props)
      this.state = {
         job_slug: props.match.params.slug,
         job: null,
         agency: {},
         categories_names: [],
         disabled_apply: true,
         logined: localStorage.getItem('id') ? true : false
      }
   }

   componentDidMount() {
      this.onStart()
   }

   onStart = async() => {
      const {job_slug, logined} = this.state
      let response
      if(window.location.pathname === `/agency/preview-job/${job_slug}`){
         response = await api.get(`wp/v2/jobs?slug=${job_slug}&status=draft`)
         if(response.data.length === 0){
            response = await api.get(`wp/v2/jobs?slug=${job_slug}`)
         }
      }else{
         if(logined) {
            response = await api.get(`wp/v2/jobs?slug=${job_slug}`)
         }else{
            response = await api_public.get(`wp/v2/jobs?slug=${job_slug}`)
         }
      }
      if(response.data.length === 0){ // Post Deleted or does not exists
         window.location.replace("/")
      }
      const job = response.data[0]
      let author_link = job._links.author[0].href
      const author = await api_public.get(author_link)
      let agency = author.data
      this.setState({ job, agency })
      const categories_names = job.categories_names
      this.setState({ categories_names })
      if(logined){
         let app_res = await api.get(`adhoc/v1/check_appliable?job_id=${job.id}`)
         if(app_res.data.appliable){
            this.setState({ disabled_apply: false })
         }else{
            this.setState({ disabled_apply: true })
         }
      }
   }

   onApply = async() => {
      const {job} = this.state
      const app_res = await api.post(`adhoc/v1/apply`, {job_id: job.id})
      const app = app_res.data
      if(app.application_id){
         toast.success("Successfully Applied")
         this.setState({ disabled_apply: true })
      }else{
         toast.error(`Failed to Apply - ${app.message}`)
      }
   }


   render() {
      const {job, agency, categories_names, disabled_apply} = this.state
      const {t} = this.props

      if(job){
         return(
            <Container id="job">


               <Card>
                  <CardBody>
                     <h1>{job.title.rendered} - {job.views} Views</h1>
                     <h3>{t('Description')}</h3>
                     <p dangerouslySetInnerHTML={{ __html: job.content.rendered}} ></p>

                  </CardBody>
               </Card>
               {job.acf.location &&
                  <Card>
                     <CardBody>
                        <CardText>{t('Location:')} {job.acf.location}</CardText>
                        <Map
                           lat={job.acf.location_lat} lng={job.acf.location_lng}
                        />
                     </CardBody>
                  </Card>
               }
               {job.acf.start_date &&
                  <Card>
                     <CardBody>
                        <CardText>{t('Date:')} {job.acf.start_date} to {job.acf.end_date}</CardText>
                     </CardBody>
                  </Card>
               }
               {job.acf.no_of_slot_total &&
                  <Card>
                     <CardBody>
                        <CardText>{t('Total Number of slot')}: {job.acf.no_of_slot_total}</CardText>
                     </CardBody>
                  </Card>
               }
               {job.acf.no_of_slot_left &&
                  <Card>
                     <CardBody>
                        <CardText>{t('Number of slot Left')}: {job.acf.no_of_slot_left}</CardText>
                     </CardBody>
                  </Card>
               }

               {categories_names &&
                  <Card >
                     <CardBody>
                        <CardText>{t('Category:')} {categories_names.join(', ')}</CardText>
                     </CardBody>
                  </Card>
               }

               {agency &&
                  <Card>
                     <CardBody>
                        <div className="row">
                           <div className="col-md-2">
                              {agency.acf.logo && agency.acf.logo.url &&
                                 <img src={agency.acf.logo.url} width="100%" />
                              }
                           </div>
                           <div className="col-md-10">
                              <CardTitle>{agency.acf.company_name}</CardTitle>
                              <p>{t('Business Registration Number')}: {agency.acf.business_registration_no}</p>

                              <CardText>{agency.acf.company_info}</CardText>

                              {agency.acf.contact_person_no &&
                                 <CardText>{t('Contact Person Number')}: {agency.acf.contact_person_no}</CardText>
                              }
                              {agency.acf.website &&
                                 <CardText>{t('Website')}: <a href={agency.acf.website} target="_blank" rel="nofollow" >{agency.acf.website}</a></CardText>
                              }
                           </div>
                        </div>

                     </CardBody>
                  </Card>
               }

               <Button onClick={this.onApply}
                  className="btn-block" disabled={disabled_apply} >
                  {this.state.logined ?
                     <span>{t('Apply Now')}</span>
                     :
                     <span>{t('Login to Apply')}</span>
                  }
               </Button>

            </Container>
         )
      }else{
         return <Container><Loader /></Container>
      }
   }
}

export default withI18n()(Job)
