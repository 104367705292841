import React,{Component} from 'react'
import { GoogleMap, Marker, withGoogleMap, withScriptjs, SearchBox } from "react-google-maps"

const Map = withGoogleMap( (props) =>
<GoogleMap defaultZoom={14}
   defaultCenter={{ lat: 1.352083, lng: 103.819836}}
   center={{ lat: props.lat, lng: props.lng }} >
      <Marker position={{ lat: props.lat, lng: props.lng }} />
   </GoogleMap>
)

class MyMap extends Component {

   render() {
      const {lat, lng} = this.props
      return(
         <Map
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `500px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
            lat={parseFloat(lat)} lng={parseFloat(lng)}
         />
      )
   }
}

export default MyMap
