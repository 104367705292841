import React, {Component} from 'react'
import {Container, Card, CardBody} from 'mdbreact'
import CircularProgress from '@material-ui/core/CircularProgress';


class Loader extends Component {

   render() {
      return (
         <Card>
            <CardBody>
               <div className="text-center spacious">
                  <CircularProgress  />
               </div>
            </CardBody>
         </Card>
      )
   }
}

export default Loader
