import React, { Component } from "react";
import {Link} from 'react-router-dom';
import api from 'services/api';
import { toast, Container, Button, Modal, ModalBody, ModalHeader, ModalFooter, Row, Input, Card, CardBody, CardImage, CardTitle, CardText } from 'mdbreact';
import MButton from '@material-ui/core/Button';
import Loader from 'components/Loader'
import {withI18n} from 'react-i18next'


class Profile extends Component {

   constructor() {
      super()
      this.state = {
         agency: null,
         logo_attached: false,
         logo: null,
         passwords: {"btn_disabled": false}
      }
   }

   componentDidMount() {
      this.onStart()
   }

   onStart = async() => {
      const response = await api.get(`wp/v2/users/me`)
      const agency = response.data
      console.log(agency);
      this.setState({agency})
   }
   updateText = (e, f) => {
      let {agency} = this.state
      agency.acf[f] = e.target.value
      this.setState({agency})
   }
   updatePassword = (e, f) => {
      let {passwords} = this.state
      passwords[f] = e.target.value
      this.setState({passwords})
   }
   updateLogo = async(e) => {
      const {agency} = this.state
      let file = e.target.files[0]
      this.setState({ logo: file, logo_attached: true })
   }

   onUpdate = async(e) => {
      e.preventDefault()
      const {agency, logo} = this.state

      if(logo){
         let formData = new FormData()
         formData.append('file', logo)
         const upload = await api.post(`wp/v2/media`, formData)
         agency.acf.logo = upload.data.id
      }

      let data = {}
      data.fields = agency.acf
      const response = await api.post(`wp/v2/users/${agency.id}`, data)
      toast.success("Updated")
      this.onStart()
      this.setState({logo_attached: false, logo: null})
   }
   onUpdatePassword = async() => {
      const {passwords, agency} = this.state

      if(!passwords.current_password || !passwords.new_password || !passwords.password_confirm){
         toast.warn("Fields are empty")
         return
      }
      if(passwords.new_password !== passwords.password_confirm){
         toast.warn("Passwords don't match")
         return
      }
      this.setState({ passwords: {...passwords, btn_disabled: true} })

      try{
         const response = await api.post(`adhoc/v1/user/change_password`, passwords)
         const {data} = response
         if(data.code == 200){
            toast.success("Password Changed")
         }
      } catch(e) {
         toast.error(e.response.data.message)
      }
      this.setState({ passwords: {current_password: '', new_password: '', password_confirm: '' , btn_disabled: false} })

   }

   render() {
      const {agency, passwords} = this.state
      const {t} = this.props
      if(agency){
         return(
            <Container id="profile">
               <Card>
                  <CardBody>
                     <h1>{t('Company Profile')}</h1>

                     <form onSubmit={this.onUpdate}>
                        {[['Company Name','building','text','company_name'],['Business Registration Number','file','text','business_registration_no'],
                        ['Office Contact Number','phone','text','office_no'],['Contact Person Number','phone','text','contact_person_no'],
                        ['Company Info','info-circle','textarea','company_info'],['Company Website','chrome','text','website']].map( (a, i) => (
                           <Input label={t(a[0])} icon={a[1]}  type={a[2]} validate key={i}
                              onChange={(e)=>{ this.updateText(e, a[3]) } } value={agency.acf[a[3]]}
                           />
                        ))}

                        {agency.acf.logo &&
                           <div className="spacious"><img src={agency.acf.logo.url} width="200px" ></img></div>
                        }

                        <input accept="image/*" className='hide' id="contained-button-file" type="file" onChange={this.updateLogo} />
                        <label htmlFor="contained-button-file">
                           <MButton variant="contained" component="span" color="primary" >
                              {agency.acf.logo ?
                                 <span>{t('Update Logo')}</span>
                                 :
                                 <span>{t('Upload Logo')}</span>
                              }
                           </MButton>
                        </label>
                        {this.state.logo_attached &&
                           <div>{t('Logo Attached')}</div>
                        }

                        <Button block className="spacious" type="submit"  >
                           {t('Update')}
                        </Button>

                     </form>

                  </CardBody>
               </Card>

               <Card>
                  <CardBody>
                     <CardTitle>{t('Account')}</CardTitle>

                     <Input label={t('Email')} icon="envelope" type="text" value={agency.name} disabled/>

                     {[['Current Password','current_password'],['New Password','new_password'],['Confirm Password','password_confirm']].map((a, i) => (
                        <Input label={t(a[0])} icon='lock'  type='password' validate key={i}
                           onChange={(e)=>{ this.updatePassword(e, a[1]) } } value={passwords[a[1]]}
                        />
                     ))}

                     <Button block className="spacious" onClick={this.onUpdatePassword} disabled={passwords.btn_disabled ? true : false }>
                        {passwords.btn_disabled ?
                           <span>{t('Updating')}</span>
                           :
                           <span>{t('Update Password')}</span>
                        }
                     </Button>

                  </CardBody>
               </Card>
            </Container>
         )
      }else{
         return <Container><Loader /></Container>
      }
   }
}

export default withI18n()(Profile)
