import React, { Component } from "react";
import {Link} from 'react-router-dom';
import api_public from 'services/api_public';
import {toast, Container} from 'mdbreact';

class VerifyRegister extends Component {

   constructor() {
      super()
      this.state = {

      }
   }

   async componentDidMount() {
      const {params} = this.props.match
      const user_id = params.id
      const verify_lock = params.lock
      const res = await api_public.post(`adhoc/v1/verify`, {user_id, verify_lock})
      window.location.href = "/login#verified"

   }

   render() {
      return(
         <Container>
            <h1>Verifying</h1>

         </Container>
      )
   }
}

export default VerifyRegister
