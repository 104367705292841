import React, {Component} from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {toast, Container, Card, CardBody, CardTitle} from 'mdbreact';
import {Link} from 'react-router-dom';
import api from 'services/api';
import Axios from 'axios'
import Loader from './Loader'
import moment from 'moment'
import { withI18n } from 'react-i18next';


class ApplicationsTab extends Component {

   constructor(props) {
      super(props)
      this.state = {
         id: props.id,
         applications: null
      }
   }

   componentDidMount() {
      let tab = window.location.hash.substr(1)
      if(tab === 'applications'){
         this.onStart()
      }

   }

   onStart = async() => {
      const {id} = this.state
      const res = await api.get(`wp/v2/applications?status=draft&job_id=${id}`)
      let applications = res.data
      let promises = []
      applications.forEach( app => {
         const {user_id} = app.acf
         const user_res = api.get(`wp/v2/users/${user_id}`)
         promises.push(user_res)
      })

      Axios.all(promises).then( (results) => {
         results.forEach( (result, i) => {
            applications[i].user = result.data.acf
         })
         this.setState({ applications })
      })

   }



   render(){
      const {applications, id} = this.state
      const { SearchBar } = Search
      const {t} = this.props

      if(applications){
         return(
               <Card>
                  <CardBody>
                     <h1>{t('Applications')}</h1>
                     <ToolkitProvider search
                        keyField="id" data={ applications } columns={ columns } >
                        {
                           props => (
                              <div>
                                 <SearchBar { ...props.searchProps } className="search-bar" />
                                 <BootstrapTable
                                    { ...props.baseProps }
                                    pagination={ paginationFactory({sizePerPage: 25}) }
                                 />
                              </div>
                           )
                        }
                     </ToolkitProvider>
                  </CardBody>
               </Card>
         )
      }else{
         return (
            <Loader />
         )
      }
   }
}
export default withI18n()(ApplicationsTab)

const columns = [{
   dataField: 'user.first_name',
   text: 'Applicants',
   sort: true,
   formatter: (cell, row) => {
      return <Link to={`/agency/user/${row.acf.user_id}`} target="_blank" >{row.user.first_name} {row.user.last_name} <i className="fa fa-external-link"></i></Link>
   },
},{
   dataField: 'user.mobile_no',
   text: 'Mobile Number',
   sort: true,
   formatter: (cell, row) => {
      return row.user.mobile_no
   }
},{
   dataField: 'date',
   text: 'Applied on',
   sort: true,
   formatter: (cell, row) => {
      return moment(cell).format('h:mm A, Do MMM YYYY')
   }
}]
