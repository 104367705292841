import React, {Component} from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import {toast, Container, Card, CardBody, CardTitle, Row, Input, Button} from 'mdbreact';
import {Link, Redirect} from 'react-router-dom';
import api from 'services/api';
import Loader from './Loader'
import Map from './Map'
import { withI18n } from 'react-i18next';

import DatePicker from 'material-ui-pickers/DatePicker';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import MSelect from '@material-ui/core/Select';
import MMenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import MInputLabel from '@material-ui/core/InputLabel';
import MChip from '@material-ui/core/Chip';
import MInput from '@material-ui/core/Input';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
   PaperProps: {
      style: {
         maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
         width: 250,
      },
   },
};

class EditJobTab extends Component {

   constructor(props) {
      super(props)
      this.state = {
         job_id: props.id,
         job: null,
         editorState: EditorState.createEmpty(),
         onRedirectJobs: false,
         cats: null,
         selected_cats: [],
         address: {name: '', lat: 1.352083, lng: 103.819836},
      }
   }

   componentDidMount() {
      let tab = window.location.hash.substr(1)
      if(tab !== 'applications'){
         this.onGetJob()
      }
   }


   onGetJob = async() => {
      const {job_id} = this.state
      const job_response = await api.get(`wp/v2/jobs/${job_id}`)
      const {data} = job_response
      let job = {fields: data.acf}
      let array = ['start_date', 'end_date', 'closing_date']
      array.forEach( (type, i) => {
         if(data.acf[type]){
            job.fields[type] = new Date(data.acf[type])
         }else{
            job.fields[type] = null
         }
      })

      job.title = data.title.rendered
      job.content = data.content.rendered
      job.status = data.status
      job.categories = data.categories

      this.setState({ job })
      const blocksFromHtml = htmlToDraft(data.content.rendered);
      const { contentBlocks, entityMap } = blocksFromHtml;
      const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({editorState})

      const cat_response = await api.get(`wp/v2/categories`)
      const cats = cat_response.data
      this.setState({cats})
   }


   changeText = (e, f) => {
      let {job} = this.state
      job[f] = e.target.value
      this.setState( {job} )
   }
   changeTextField = (e, f) => {
      let {job} = this.state
      job.fields[f] = e.target.value
      this.setState( {job} )
   }
   changeAddress = address => {
      let {job} = this.state
      job.fields.location = address
      this.setState({ job })
   }
   changeDate = (date, f) => {
      let {job} = this.state
      date = new Date(date)
      let format_date = date.getFullYear() + '-' + (date.getMonth() + 1 ) + '-' + date.getDate()
      job.fields[f] = format_date
      this.setState({job})
   }
   onEditorStateChange = (editorState) => {
      const {job} = this.state
      let text = draftToHtml(convertToRaw(editorState.getCurrentContent()))
      job.content = text
      this.setState({
         editorState, job
      });
   };

   onUpdate = async() => {
      const {job, job_id} = this.state
      const response = await api.post(`wp/v2/jobs/${job_id}`, job)
      const {data} = response
      if(data){
         toast.success("Successfully Updated")
      } else{
         toast.error("Error Updating")
      }
   }
   onDelete = async() => {
      let c = window.confirm("Are you sure to delete this Job?")
      if (!c) return
      const {job, job_id} = this.state
      const response = await api.delete(`wp/v2/jobs/${job_id}`)
      const {data} = response
      toast.success("Job Successfully Deleted. Redirecting")
      this.setState({ onRedirectJobs: true })
   }
   changeStatus = (e) => {
      let {job} = this.state
      job.status = e.target.value
      this.setState({ job })
   }
   changeCatSelect = (e) => {
      this.setState({ job: {...this.state.job, categories: e.target.value}})
   }

   selectAddress = address => {
      let _this = this
      let {job} = this.state
      geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
         console.log('Success', latLng)
         job.fields['location_lat'] = latLng.lat
         job.fields['location_lng'] = latLng.lng
         _this.setState({ job })
      })
      .catch(error => console.error('Error', error));
   };


   render(){
      const {job, id, onRedirectJobs, editorState, cats} = this.state
      const {t} = this.props

      if(job){
         if(onRedirectJobs){
            return(
               <Redirect to="/agency/jobs" />
            )
         }else{
            return(
               <Card >
                  <CardBody>
                     <h1>{job.title}</h1>

                     <form>

                        <Input label={t('Job Title')} value={job.title} onChange={(e)=>this.changeText(e, 'title')} />
                        <label>{t('Job Description')}</label>
                        <Editor
                           editorState={editorState}
                           editorClassName="editors"
                           onEditorStateChange={this.onEditorStateChange}
                           toolbar={{
                              options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'remove', 'history']
                           }}
                        />


                        <PlacesAutocomplete
                           value={job.fields.location}
                           onChange={this.changeAddress}
                           onSelect={this.selectAddress}
                           searchOptions={searchOptions} >
                           {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                              <div>
                                 <Input label={t('Location')} {...getInputProps({ placeholder: 'Search Places ...', className: 'location-search-input'})} />
                                 <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                       const className = suggestion.active ? 'suggestion-item-active' : 'suggestion-item'

                                       return (
                                          <div {...getSuggestionItemProps(suggestion, { className})} >
                                             <span>{suggestion.description}</span>
                                          </div>
                                       );
                                    })}
                                 </div>
                              </div>
                           )}
                        </PlacesAutocomplete>


                        <Map
                           lat={job.fields.location_lat} lng={job.fields.location_lng}
                        />

                        <Row>
                           <div className="col-md-6">
                              <label className="col-md-4">{t('Start Date')}</label>
                              <DatePicker autoOk className="col-md-6"
                                 value={job.fields.start_date}
                                 onChange={(e)=>this.changeDate(e,'start_date')}
                              />

                           </div>
                           <div className="col-md-6">
                              <label className="col-md-4">{t('End Date')}</label>
                              <DatePicker autoOk className="col-md-6"
                                 value={job.fields.end_date}
                                 onChange={(e)=>this.changeDate(e,'end_date')}
                              />
                           </div>
                        </Row>
                        <Row>
                           <div className="col-md-6">
                              <label className="col-md-4">{t('Ad Closing Date')}</label>
                              <DatePicker autoOk className="col-md-6"
                                 value={job.fields.closing_date}
                                 onChange={(e)=>this.changeDate(e,'closing_date')}
                              />
                           </div>
                        </Row>
                        <Input type="number" label={t('Number of slot Total')}
                           value={job.fields.no_of_slot_total} onChange={(e)=>this.changeTextField(e, 'no_of_slot_total')}
                        />
                        <Input type="number" label={t('Available slot Left')}
                           value={job.fields.no_of_slot_left} onChange={(e)=>this.changeTextField(e, 'no_of_slot_left')}
                        />

                        <Row>
                           <div className="col-md-2">{t('Categories')}</div>
                           <MSelect
                              className="col-md-10"
                              multiple
                              value={job.categories}
                              onChange={this.changeCatSelect}
                              input={<MInput id="select-multiple" />}
                              MenuProps={MenuProps} >
                              {cats && cats.map( (cat, i) => (
                                 <MMenuItem key={i} value={cat.id}>
                                    {cat.name}
                                 </MMenuItem>
                              ))}
                           </MSelect>
                        </Row>

                        <Row>
                           <div className="col-md-1">{t('Status')}</div>
                           <div className="col-md-6">
                              <MSelect value={job.status} onChange={this.changeStatus} >
                                 <MMenuItem value='draft'>{t('Draft')}</MMenuItem>
                                 <MMenuItem value='publish'>{t('Publish')}</MMenuItem>
                              </MSelect>
                           </div>
                        </Row>

                        <Button onClick={this.onUpdate} >
                           {t('Update')}
                        </Button>
                        <Button color="danger" onClick={this.onDelete} >
                           {t('DELETE')}
                        </Button>

                     </form>

                  </CardBody>
               </Card>
            )
         }
      }else{
         return (
            <Loader />
         )
      }
   }
}
export default withI18n()(EditJobTab)

const searchOptions = {
   bounds: new window.google.maps.LatLngBounds(
      new window.google.maps.LatLng(1.495173, 103.570687),
      new window.google.maps.LatLng(1.23, 105)
   )
}
