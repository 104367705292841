import React, { Component } from 'react'
import { hot } from 'react-hot-loader'
import { BrowserRouter as Router, Route, Link, Redirect, withRouter, Switch} from 'react-router-dom'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';


import Wrapper from './components/Wrapper';
import Home from './pages/Home';
import Job from './pages/Job';
import VerifyRegister from './pages/VerifyRegister';
import Agencies from './pages/agency'

import UserLogin from './pages/user/Login';
import UserRegister from './pages/user/Register';
import UserProfile from './pages/user/Profile';

import AgencyLogin from './pages/agency/Login';
import AgencyRegister from './pages/agency/Register';
import AgencyProfile from './pages/agency/Profile';
import AgencyDashboard from './pages/agency/Dashboard';
import AgencyJobs from './pages/agency/Jobs';
import AgencyEditJob from './pages/agency/EditJob';
import AgencyUser from './pages/agency/User'


const theme = createMuiTheme({
   typography: {
    useNextVariants: true,
  },
});

const URoute = ({ component: Component, ...rest }) => (
   <Route {...rest} render={ (props) => (
      localStorage.getItem('token') && localStorage.getItem('role') === 'user'
      ?
      <Wrapper component={
         <Component {...props}
         />
      } />
      :
      <Redirect to={{
         pathname: '/',
         state: { from: props.location }
      }} />
   )}
/>
)
const ARoute = ({ component: Component, ...rest }) => (
   <Route {...rest} render={ (props) => (
      localStorage.getItem('token') && localStorage.getItem('role') === 'agency'
      ?
      <Wrapper component={
         <Component {...props}
         />
      } />
      :
      <Redirect to={{
         pathname: '/',
         state: { from: props.location }
      }} />
   )}
/>
)

const PRoute = ({ component: Component, ...rest }) => (
   <Route {...rest} render={ (props) => (
      <Wrapper component={
         <Component {...props}
         />
      } />
   )}
/>
)


class App extends Component {
   render() {
      return (
         <MuiThemeProvider theme={theme} >
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
               <Router >
                  <div id="app">
                     <PRoute path="/" component={Home} exact />
                     <PRoute path="/job/:slug" component={Job} />
                     <PRoute path="/agency" component={Agencies} />
                     <PRoute path="/login" component={UserLogin}  />
                     <PRoute path="/register" component={UserRegister}  />
                     <PRoute path="/verify/:id/:lock" component={VerifyRegister}  />
                     <URoute path="/profile" component={UserProfile} exact />
                     <PRoute path="/agency/login" component={AgencyLogin} exact />
                     <PRoute path="/agency/register" component={AgencyRegister} exact />
                     <ARoute path="/agency/profile" component={AgencyProfile} exact />
                     <ARoute path="/agency/dashboard" component={AgencyDashboard} />
                     <ARoute path="/agency/jobs" component={AgencyJobs} exact />
                     <ARoute path="/agency/edit-job/:id" component={AgencyEditJob} exact />
                     <ARoute path="/agency/preview-job" component={Home} exact />
                     <ARoute path="/agency/preview-job/:slug" component={Job} />
                     <ARoute path="/agency/user/:id" component={AgencyUser} />
                  </div>
               </Router>
            </MuiPickersUtilsProvider>
         </MuiThemeProvider>
      );
   }
}

export default hot(module)(App)



Date.prototype.addDays = function(days) {
   let date = new Date(this.valueOf());
   date.setDate(date.getDate() + days);
   return date;
}
